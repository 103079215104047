<template>
  <v-footer light class="transparent my-3" :class="{ 'px-5': $vuetify.breakpoint.mdAndUp }">
      <template v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn
          v-for="(m, i) in menu"
          active-class="primary--text"
          flat
          small
          :class="{'v-btn--active': currentRoute.includes(m.name) }"
          :href="m.path"
          :key="i"
          @click="$vuetify.goTo(m.path, { offset: -100 })">
          <span v-text="m.text" />
        </v-btn>
      </template>
      <v-spacer />
      <v-btn icon small href="https://www.linkedin.com/in/calebbergman/" title="LinkedIn">
        <v-icon>fab fa-linkedin</v-icon>
      </v-btn>
      <v-btn icon small href="https://stackoverflow.com/users/1370941/caleb-bergman" title="StackOverflow">
        <v-icon>fab fa-stack-overflow</v-icon>
      </v-btn>
      <v-btn icon small href="https://github.com/calebbergman" title="GitHub">
        <v-icon>fab fa-github</v-icon>
      </v-btn>
      <span :class="{'mr-2': $vuetify.breakpoint.mdAndDown}">
        &copy; 2020 Caleb Bergman
      </span>
  </v-footer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('app')

export default {
  computed: {
    ...mapGetters(['menu']),
    currentRoute() {
      return this.$route.name || ''
    }
  }
}
</script>
